import React from 'react';
import styled from 'styled-components';
import {
  SYS_BREAKPOINT_MD,
  SYS_SPACE_FIXED_50,
  SYS_SPACE_RELATIVE_XS,
  SYS_SPACE_RELATIVE_MD,
  SYS_SPACE_RELATIVE_LG,
  SYS_SPACE_RELATIVE_XL,
} from '@oneaudi/unified-web-common';
import { Headline, Text, TextButton } from '@oneaudi/unified-web-components';
import { renderTextWithFootnotesReferencesV2 } from '@oneaudi/feature-app-utils';
import { EventItem } from '../../../types';
import { Tracking } from '../../utils/useTracking';

const Container = styled.div`
  display: grid;
  padding: ${SYS_SPACE_RELATIVE_LG} 0;

  grid-template:
    'headline'
    'copy'
    'details'
    'button';

  @media (min-width: ${SYS_BREAKPOINT_MD}) {
    grid-template:
      'headline copy'
      'details  button';

    grid-template-columns: 1fr 1fr;
    row-gap: ${SYS_SPACE_RELATIVE_LG};
    column-gap: ${SYS_SPACE_RELATIVE_XS};
    padding: ${SYS_SPACE_RELATIVE_XL} 0;
  }
`;

const GridAreaHeadline = styled(Headline)`
  grid-area: headline;
  padding-bottom: ${SYS_SPACE_RELATIVE_MD};

  sup {
    // footnotes too small fix
    vertical-align: super;
    font-size: 16px !important;
    font-weight: bold;
  }
`;

const GridAreaCopy = styled(Text)`
  grid-area: copy;

  sup {
    // footnotes too small fix
    font-size: 10px !important;
    font-weight: bold;
  }
`;

const GridAreaDetails = styled.div`
  grid-area: details;
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: 100%;

  gap: ${SYS_SPACE_FIXED_50};
  padding: ${SYS_SPACE_RELATIVE_LG} 0;

  sup {
    // footnotes too small fix
    font-size: 10px !important;
    font-weight: bold;
  }

  @media (min-width: ${SYS_BREAKPOINT_MD}) {
    padding: 0;
  }
`;

const GridAreaButton = styled(TextButton)`
  grid-area: button;
  display: flex;
  flex-direction: row;
  justify-self: left;
  width: 100%;

  @media (min-width: 440px) {
    width: 144px;
  }

  @media (min-width: ${SYS_BREAKPOINT_MD}) {
    justify-self: right;
  }
`;

const formatDateString = (
  date?: string,
): { day: string; month: string; year: string } | undefined => {
  if (!date) {
    return undefined;
  }

  const formattedDate = new Date(date);
  const dayStr =
    formattedDate.getDate() > 9
      ? formattedDate.getDate().toString()
      : `0${formattedDate.getDate()}`;
  const yearStr = formattedDate.getFullYear().toString();
  const monthAsString = formattedDate.toLocaleDateString('default', { month: 'long' });

  return {
    day: dayStr,
    month: monthAsString,
    year: yearStr,
  };
};

export interface EventTeaserItemProps {
  event: EventItem;
  tracking: Tracking;
}

export const EventTeaserItem: React.FC<EventTeaserItemProps> = (props) => {
  const { event, tracking } = props;
  const startDate = formatDateString(event.startDate);
  const endDate = formatDateString(event.endDate);

  return (
    <Container>
      <GridAreaHeadline
        tag="h3"
        className="event-title"
        variant={{ type: 'headline', order: '1', weight: 'normal' }}
      >
        {renderTextWithFootnotesReferencesV2(event.eventTitle)}
      </GridAreaHeadline>
      <GridAreaCopy variant={{ type: 'copy', style: 'normal', order: '1' }}>
        {renderTextWithFootnotesReferencesV2(event.description)}
      </GridAreaCopy>
      <GridAreaDetails>
        <Text>
          {`${startDate?.day} ${startDate?.month} ${startDate?.year}`}
          {event.endDate && ` - ${endDate?.day} ${endDate?.month} ${endDate?.year}`}
        </Text>
        {event.location && <Text>{renderTextWithFootnotesReferencesV2(event.location)}</Text>}
      </GridAreaDetails>
      {event.cta && event.cta.url && event.cta.label && (
        <GridAreaButton
          href={event.cta.url}
          target={event.cta.openInNewTab ? '_blank' : undefined}
          rel="noopener noreferrer"
          aria-label={event.cta.ariaLabel}
          variant="secondary"
          onClick={() =>
            tracking.sendClickEvent(
              event.cta.label,
              event.cta.label || '',
              `${event.eventTitle || ''}~${event.location || ''}`,
            )
          }
        >
          {event.cta.label}
        </GridAreaButton>
      )}
    </Container>
  );
};
