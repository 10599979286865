import { ContentFragment, Fields } from '@oneaudi/falcon-tools';

export type ThemeType = 'Dark' | 'Light';
export type NodeEnvironment = 'development' | 'production';

export const FA_LOCATOR_ID = `fa-event-teaser`;
export const FA_LOCATOR = `[data-test-id=${FA_LOCATOR_ID}]`;

export interface EventItem {
  eventTitle: string;
  description: string;
  startDate: string;
  endDate?: string;
  location?: string;
  cta: {
    label: string;
    url: string;
    ariaLabel: string;
    openInNewTab: boolean;
  };
}

export interface EditorJsonContent {
  __type?: 'editor.json';
  themeOption?: {
    theme?: ThemeType;
  };
  headingOption: {
    headline: string;
  };
  eventsOption?: {
    events?: EventItem[];
  };
}

type FalconContentEvenItem = {
  id: string;
  path: string;
  contentPath: string;
  status: string;
  created: string;
  modified: string;
  title: string;
  description: string;
  model: {
    id: string;
    path: string;
    title: string;
    metadata: {
      baseUrl: string;
      id: string;
      serverSrc: string;
      src: string;
      version: string;
    };
  };
  fields: {
    eventTitle: string;
    location: string;
    description: string;
    startDate: string;
    endDate: string;
    cta_label: string;
    cta_url: string;
    cta_ariaLabel: string;
    cta_openInNewTab: boolean;
  };
};

export interface FalconContent
  extends Partial<
    Omit<ContentFragment, 'fields'> & {
      // Redefined fields to be match headless data structure. We need to convert FalconContent to a generic
      // type e.g passing in FalconContent<FalconContentEvenItem[]>
      fields: Record<string, string | FalconContentEvenItem[] | Fields>;
    }
  > {
  __type: 'aem-headless';
  fields: {
    themeOption_theme: ThemeType;
    headingOption_headline: string;
    eventsOption_events: FalconContentEvenItem[];
  };
}
export interface AppContent {
  theme?: ThemeType;
  headline: string;
  events: EventItem[];
}
