import React from 'react';
import styled, { DefaultTheme } from 'styled-components';
import {
  SYS_SPACE_RELATIVE_2_XL,
  ThemeProvider,
  SYS_BREAKPOINT_SM,
  SYS_SPACE_RELATIVE_XL,
} from '@oneaudi/unified-web-common';
import { Headline } from '@oneaudi/unified-web-components';
import { AppContent, FA_LOCATOR_ID } from '../../../types';
import { useTracking } from '../../utils/useTracking';
import { EventTeaserItem } from './EventTeaserItem';

const Container = styled.div`
  ${({ theme }: { theme: DefaultTheme }) => {
    return `
      background-color: ${theme.global.color.canvas.default};
      margin: ${theme.name === 'dark' ? `${SYS_SPACE_RELATIVE_2_XL} 0` : `${SYS_SPACE_RELATIVE_2_XL} var(--page-margin)`}; 
      padding: ${theme.name === 'dark' ? `${SYS_SPACE_RELATIVE_2_XL} var(--page-margin)` : 0};  

     @media (min-width: ${SYS_BREAKPOINT_SM}) {
      margin: ${SYS_SPACE_RELATIVE_2_XL} var(--page-margin); 
      padding: ${theme.name === 'dark' ? `${SYS_SPACE_RELATIVE_XL}` : 0};  
     }
    `;
  }}
`;

const EventTeaserItems = styled.div`
  display: flex;
  flex-direction: column;
`;

export const EventTeaser: React.FC<AppContent> = (props) => {
  const { theme, headline, events } = props;

  const ref = React.useRef(null);

  const tracking = useTracking(ref, {
    implementer: 37,
  });

  React.useEffect(() => {
    tracking.sendReadyEvent();
    tracking.registerImpressionTracking();
  }, []);

  return events.length > 0 ? (
    <ThemeProvider theme={{ name: theme === 'Dark' ? 'dark' : 'light' }}>
      <Container ref={ref} data-test-id={FA_LOCATOR_ID}>
        <Headline tag="h2" variant={{ type: 'headline', order: '1', weight: 'normal' }}>
          {headline}
        </Headline>

        <EventTeaserItems>
          {events.map((event, i) => (
            <EventTeaserItem key={`event-teaser-${String(i)}`} event={event} tracking={tracking} />
          ))}
        </EventTeaserItems>
      </Container>
    </ThemeProvider>
  ) : null;
};
